@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $white;
    background-image: linear-gradient(to bottom, #EEE1CE, #FFE8D5);
    color: $primary-color;
    font-family: $primary-font;
    letter-spacing: .08em;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(1800);
    position: relative;
    z-index: 5;
}

@keyframes marquee {
	0% {
		transform: translate3d(var(--move-initial), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final), 0, 0);
	}
}

.btn{
    display: inline-block;
    overflow: hidden;
    position: relative;
    padding: rem(10) rem(15) rem(8);
    text-align: center;
    border: 1px solid $white;
    font-size: rem(12);
    text-decoration: none;
    border-radius: rem(25);
    .marquee-text{
        pointer-events: none;
        width: fit-content;
        display: flex;
        position: absolute;
        color: $white;
        background-color: $primary-color;
        top: 0;
        left: 0;
        padding: rem(10) 0 rem(8);
        white-space: nowrap;
        --offset: 1rem;
        --move-initial: calc(-25% + var(--offset));
        --move-final: calc(-50% + var(--offset));
        transform: translate3d(var(--move-initial), 0, 0);
        animation: marquee 2s linear infinite;
        opacity: 0;
    }
    @include hover{
        background-color: $white;
        color: $black;
        .marquee-text{
            opacity: 1;
        }
    }
}

.social{
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: rem(16);
    li{
        display: inline-block;
        margin: .5em;
    }
}
.newsletter{
    .inputs-wrap{
        display: flex;
        align-items: center;
    }
    input{
        flex: 1;
        border: 1px solid $white;
        color: $white;
        border-right: none;
        border-top-left-radius: rem(20);
        border-bottom-left-radius: rem(20);
        background: none;
        padding: rem(10) rem(10) rem(8);
        font-size: rem(12);
        width: rem(250);
        &:focus{
            outline: none;
        }
        &::placeholder{
            color: $white;
            text-transform: uppercase;
            letter-spacing: .08em;
        }
    }

    button{
        text-transform: uppercase;
        transition: all .3s;
        background: transparent;
        border-radius: rem(20);
        letter-spacing: .08em;
        color: $white;
        cursor: pointer;
        width: auto;
        margin-left: rem(-18);
        @include hover{
            background-color: $primary-color;
            color: $black;
            border-color: $primary-color;
        }
    }
}

#header{
    background-color: $black;
    color: $white;
    margin-bottom: rem(100);
    position: relative;
    .bg-video{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }
    h1{
        margin-bottom: 0;
    }
    .nav{
        font-family: $header-font;
        letter-spacing: 0;
        font-size: rem(18);
        a{
            display: inline-block;
            text-decoration: none;
            &+a{
                margin-left: rem(40);
            }
        }
    }

    .wrap{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    @media screen and (max-width: 800px){
        .desktop{
            display: none;
        }
        .wrap{
            justify-content: center;
        }
    }

    .feature{
        p{
            text-transform: uppercase;
            font-size: rem(12);
        }
        img{
            display: block;
            margin: 0 auto;
            width: rem(180);
            max-width: 100%;
        }
        .btn{
            margin-top: rem(12);
            text-transform: uppercase; 
            @include hover{
                border-color: $primary-color;
            }
        }
    }

    .container{
        min-height: 100vh;
        max-width: rem(1500);
        padding-bottom: rem(30);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .social{
        text-align: right;
    }

    .newsletter button{
        @include hover{
            border-color: $primary-color;
            background-color: $white;
            color: $black;
        }
    }
}

#main{
    @media screen and (min-width: 501px){
        section{
            margin-bottom: rem(100);
        }
    }

    .container{
        max-width: rem(900);
    }
    h2{
        font-family: $header-font;
        letter-spacing: 0;
        text-transform: capitalize;
        font-weight: 400;
        font-size: rem(53);
        text-align: center;
        margin: rem(50) 0 0;
    }

    
    .carousel{
        margin-top: rem(15);
        padding: 0 rem(30);
    }
    .slick-arrow{
        position: absolute;
        z-index: 10;
        top: calc(50% - 1.5rem);
        transform: translateY(-50%);
        background: none;
        border: none;
        font-size: rem(18);
        padding: 1px;
        width: rem(26);
        height: rem(26);
        border: 1px solid $primary-color;
        color: $primary-color;
        text-align: center;
        border-radius: rem(13);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s all;
        @include hover{
            color: $white;
            background-color: $primary-color;
        }
        
        &.prev-arrow{
            left: 0;   
        }
        &.next-arrow{
            right: 0;
        }
    }

    #music{
        .item{
            text-align: center;
            font-weight: bold;
            img{
                display: block;
                width: rem(219);
                max-width: 100%;
                height: auto;
                margin: 0 auto;
            }
            a{
                display: inline-block;
                margin-top: 1em;
                text-decoration: none;
                text-transform: uppercase;
                font-size: rem(12)
            }
        }
    }

    #video{
        .item{
            text-align: center;
            text-transform: uppercase;
            font-size: rem(12);
            padding: 0 rem(80);
            font-weight: bold;
            
            @media screen and (max-width: 800px){
                padding: 0 rem(10);
            }
        }
        .media-responsive{
            border-radius: rem(40);
        }
    }

    #events{
        width: 100%;
        max-width: rem(600);
        margin: rem(15) auto rem(40);

        .event{
            display: flex;
            font-size: rem(14);
            margin-bottom: rem(30);
            text-transform: uppercase;
            
            

            .event-date{
                flex: 1;
                text-transform: uppercase;
                font-weight: 700;
                color: $primary-color;
                font-size: rem(18);
            }
            .event-location{
                flex: 2;
                font-weight: bold;
                font-size: rem(12);
            }
            .event-links{
                flex: 1;
                text-align: right;
            }
            .btn{
                width: rem(125);
                font-size: rem(12);
                border-color: $primary-color;
                @include hover{
                    color: $white;
                    background: $primary-color;
                }
            }

            @media screen and (max-width: 500px){
                display: block;
                margin: rem(15) auto;
                text-align: center;
                
                .event-location{
                    margin: rem(5) 0;
                }
                .event-links{
                    text-align: center;
                }
            }
        }

        .more{
            text-align: center;
            .btn{
                border-color: $primary-color;
                text-transform: uppercase;
                @include hover{
                    color: $white;
                    background: $primary-color;
                }
            }
        }

        &:not(.seeall){
            .event:nth-child(n+7){
                display: none;
            }
        }
    }
}

#footer{
    margin-top: rem(100);
    .container{
        text-align: center;
        width: 100%;
        max-width: rem(600);
    }
    .newsletter{
        
        .inputs-wrap{
            display: flex;
            align-items: center;
            margin: 0 auto rem(15);
            width: 100%;
            max-width: rem(450);
        }
        input{
            border: 1px solid $primary-color;
            border-right: none;
            color: $primary-color;
            &::placeholder{
                color: $primary-color;
            }
        }
    
        button{
            border-color: $primary-color;
            color: $primary-color;
            @include hover{
                background-color: $primary-color;
                color: $white;
            }
        }
    }
    .copyright{
        margin-top: 0;
        font-size: rem(9);
        svg{
            display: block;
            margin: 0 auto rem(10);
            
            @media screen and (max-width: 1000px){
                margin: 0 auto rem(10);
            }
        }
        a{
            text-decoration: none;
        }
    }

    @media screen and (min-width: 801px){
        .mobile{
            display: none;
        }
    }
    
}