//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #F26144;
$gray: #656565;

//Fonts
$primary-font: 'Nimbus Sans Extd D', sans-serif;
$header-font: 'Vivaldi Std', sans-serif;
