@font-face {
    font-family: 'Vivaldi Std';
    src: url('../font/VivaldiStd.woff2') format('woff2'),
        url('../font/VivaldiStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nimbus Sans Extd D';
    src: url('../font/NimbusSanExtD-Bol.woff2') format('woff2'),
        url('../font/NimbusSanExtD-Bol.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nimbus Sans Extd D';
    src: url('../font/NimbusSanExtD-Lig.woff2') format('woff2'),
        url('../font/NimbusSanExtD-Lig.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nimbus Sans Extd D';
    src: url('../font/NimbusSanExtD-Reg.woff2') format('woff2'),
        url('../font/NimbusSanExtD-Reg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



